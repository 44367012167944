<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <chem-components
          :is="tab.component"
          :param="popupParam"
          @insertEquipInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'equipmentInfo',
      tabItems: [
        { name: 'equipmentInfo', icon: 'info', label: '설비 기본정보', class:"tab-orange", component: () => import(`${'./equipmentInfo.vue'}`) }
      ],
      addTabItems: [
        { name: 'equipmentInspect', icon: 'construction', label: '점검/정비이력', component: () => import(`${'./equipmentInspect.vue'}`) },
        { name: 'equipmentSpec', icon: 'account_tree', label: '설비 사양', component: () => import(`${'./equipmentSpec.vue'}`) },
        { name: 'equipmentPsm', icon: 'warning_amber', label: 'PSM 정보', component: () => import(`${'./equipmentPsm.vue'}`) },
        { name: 'equipmentEtc', icon: 'note_alt', label: '관련정보', component: () => import(`${'./equipmentEtc.vue'}`) },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.equipmentCd != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(_equipmentCd) {
      this.popupParam.equipmentCd = _equipmentCd;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>